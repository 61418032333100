import { AxiosError, AxiosResponse } from "axios";

import { message } from "@/composables/message.composable";

import router from "@/router";

const VALID_STATUSES = [200, 201, 202, 203, 204, 300, 301, 302, 303, 304];

export default class ApiHelper {
  static checkResponse(response: AxiosResponse) {
    if (VALID_STATUSES.includes(response.status)) {
      return Promise.resolve(response);
    }

    return Promise.reject(new Error(response.statusText));
  }

  static handleError(error: AxiosError) {
    if (error.request) {
      switch (error.request.status) {
        case 400:
          // Bad request
          break;

        case 401:
          // Unauthorized
          this._showMessage(error);
          this._signOut();
          break;

        case 403:
          // Forbidden
          this._showMessage(error);
          break;

        case 404:
          // Not found
          break;

        case 500:
          // Internal Server Error
          break;

        case 503:
          // Service Unavailable
          break;

        case 504:
          // Gateway Timeout
          break;

        default:
          // Network Error
          break;
      }
    } else {
      this._showErrorPage(error);
    }

    return Promise.reject(error);
  }

  private static _showErrorPage(error: AxiosError): void {
    console.error(error);
  }

  private static _showMessage(error: AxiosError): void {
    const data = error.response?.data;
    message.error(data?.data?.message ?? data?.message);
  }

  private static _signOut() {
    localStorage.removeItem("core_api_token");

    router.replace("/auth");
  }
}
