
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { NGrid, NGridItem } from "naive-ui";

import { Asset } from "@/models/asset.model";
import { useAssetsStore } from "@/stores/assets.store";

import AssetComponent from "../components/AssetComponent.vue";

export default defineComponent({
  name: "AssetsPage",

  props: {
    field: {
      type: String,
      required: true,
    },

    query: {
      type: [Array, String],
      required: true,
    },
  },

  components: {
    NGrid,
    NGridItem,

    AssetComponent,
  },

  setup(props) {
    const assetsStore = useAssetsStore();

    const assets = ref<Asset[]>([]);

    onMounted(() => {
      console.log(props);

      assets.value = assetsStore.assets.filter((asset) => {
        return props.query.includes(asset[props.field]);
      });
    });

    return { router: useRouter(), assets };
  },
});
