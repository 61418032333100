
import { computed, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";

import { NCard, NGrid, NGridItem, useThemeVars } from "naive-ui";

import { Tile } from "@/models/tile.model";
import { useTilesStore } from "@/stores/tiles.store";

export default defineComponent({
  name: "TilesPage",

  components: {
    NCard,
    NGrid,
    NGridItem,
  },

  setup() {
    const router = useRouter();
    const tilesStore = useTilesStore();

    const tiles = computed<Tile[]>({
      get: () => tilesStore.tiles,
      set: (value) => (tilesStore.tiles = value),
    });

    onMounted(() => {
      tilesStore.fetchServices().then((response) => {
        tiles.value = response.data.tiles;
        tilesStore.inhabitants = response.data.inhabitants;
      });
    });

    function routerPush(id: number) {
      router.push({ name: "tile", params: { id } });
    }

    return {
      themeVars: useThemeVars(),
      tiles,

      routerPush,
    };
  },
});
