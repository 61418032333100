
import { defineComponent, onMounted, ref } from "vue";

import { NIcon, NTabs, NTabPane } from "naive-ui";

import { Flash20Regular } from "@vicons/fluent";

import AssetType_Devices from "../components/AssetType_Devices.vue";
import AssetType_Power from "../components/AssetType_Power.vue";
import AssetType_Scenes from "../components/AssetType_Scenes.vue";
import AssetType_Rooms from "../components/AssetType_Rooms.vue";

import Tiles from "@/modules/Tiles/pages/Tiles.vue";

export default defineComponent({
  name: "AssetTypesPage",

  components: {
    NIcon,
    NTabs,
    NTabPane,

    Flash20Regular,
    AssetType_Devices,
    AssetType_Power,
    AssetType_Scenes,
    AssetType_Rooms,
    Tiles,
  },

  setup() {
    const tabsValueRef = ref<string>();

    onMounted(() => {
      const value = localStorage.getItem("current_tab");
      tabsValueRef.value = value ?? "rooms";
    });

    return {
      tabsValue: tabsValueRef,

      setCurrentTab(value: string) {
        localStorage.setItem("current_tab", value);
      },
    };
  },
});
