
import { computed, defineComponent, inject, PropType, ref, watch } from "vue";

import {
  NBadge,
  NCard,
  NGrid,
  NGridItem,
  NIcon,
  NSpace,
  useThemeVars,
} from "naive-ui";

import { useDebounceFn } from "@vueuse/core";

import {
  DeviceMeetingRoomRemote20Regular,
  CalendarSync20Regular,
  CalendarArrowDown20Regular,
} from "@vicons/fluent";

import { Asset, AssetData } from "@/models/asset.model";

import { useAssetsStore } from "@/stores/assets.store";

import DrawerComponent from "./components/DrawerComponent.vue";

export default defineComponent({
  name: "ShutterActuatorComponent",

  props: {
    asset: {
      type: Object as PropType<Asset>,
      required: true,
    },

    data: {
      type: Object as PropType<AssetData[]>,
      required: true,
    },
  },

  components: {
    NBadge,
    NCard,
    NGrid,
    NGridItem,
    NIcon,
    NSpace,

    DeviceMeetingRoomRemote20Regular,
    DrawerComponent,
    CalendarSync20Regular,
    CalendarArrowDown20Regular,
  },

  setup(props) {
    const themeVars = useThemeVars();
    const assetsStore = useAssetsStore();

    const fb = computed(() => props.data.find((_) => _.subtype == ""));

    const attrDrawerPosition = ref(false);
    const sliderValuePosition = ref(fb.value?.data.position_fb ?? 0);

    const attrDrawerSlats = ref(false);
    const sliderValueSlats = ref(fb.value?.data.shutter_slats_fb ?? 0);

    watch(
      () => fb.value?.data,
      (value, oldValue) => {
        if (value?.position_fb != oldValue?.position_fb) {
          sliderValuePosition.value = value?.position_fb ?? 0;
        }

        if (value?.shutter_slats_fb != oldValue?.shutter_slats_fb) {
          sliderValueSlats.value = value?.shutter_slats_fb ?? 0;
        }
      }
    );

    const debounceSetSpData = useDebounceFn(setSpData, 1000);

    function setSpData(attr: string, event: number) {
      attrDrawerPosition.value = false;
      attrDrawerSlats.value = false;

      const payload = {
        asset_id: props.asset.asset_id,
        subtype: "sp",
        data: {
          [attr]: event,
        },
      };

      assetsStore.setAssetData(payload);
    }

    return {
      env: inject("env"),

      themeVars,
      attrDrawerPosition,
      attrDrawerSlats,
      sliderValuePosition,
      sliderValueSlats,
      fb,

      debounceSetSpData,
    };
  },
});
