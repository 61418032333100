import { defineStore } from "pinia";

import {
  Asset,
  AssetAttribute,
  AssetData,
  AssetType,
} from "@/models/asset.model";

import Api from "@/services/Api/api.service";

export const useAssetsStore = defineStore("assetsStore", {
  state: () => {
    return {
      assets: [] as Asset[],
      assetAttributes: [] as AssetAttribute[],
      assetData: [] as AssetData[],
      assetTypes: [] as AssetType[],
    };
  },

  actions: {
    fetchAssets(params?: Record<string, string>) {
      return Api.get("/api/assets", params);
    },

    fetchAssetAttributes(params?: object) {
      return Api.get("/api/asset-attributes", params);
    },

    fetchAssetData(params?: object) {
      return Api.get("/api/asset-data", params);
    },

    fetchAssetTypes(params?: object) {
      return Api.get("/api/asset-types", params);
    },

    setAssetData(payload: object) {
      return Api.post("/api/asset-data", payload);
    },
  },
});
