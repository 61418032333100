import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_time = _resolveComponent("n-time")!
  const _component_n_tag = _resolveComponent("n-tag")!
  const _component_Person20Filled = _resolveComponent("Person20Filled")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_space = _resolveComponent("n-space")!
  const _component_n_card = _resolveComponent("n-card")!
  const _component_n_grid_item = _resolveComponent("n-grid-item")!
  const _component_n_grid = _resolveComponent("n-grid")!

  return (_openBlock(), _createBlock(_component_n_grid, { cols: "1" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order) => {
        return (_openBlock(), _createBlock(_component_n_grid_item, {
          key: order.id
        }, {
          default: _withCtx(() => [
            _createVNode(_component_n_card, {
              "theme-overrides": {
            borderColor: _ctx.themeVars.placeholderColor,
          },
              class: "shadow-xl",
              onClick: ($event: any) => (_ctx.routerPush(order.id))
            }, {
              header: _withCtx(() => [
                _createVNode(_component_n_time, {
                  time: new Date(order.date_entered),
                  format: "PPP",
                  type: "date"
                }, null, 8, ["time"])
              ]),
              "header-extra": _withCtx(() => [
                _createTextVNode(_toDisplayString(order.order_number), 1)
              ]),
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(order.service.translation.name), 1)
              ]),
              footer: _withCtx(() => [
                _createVNode(_component_n_space, {
                  align: "center",
                  justify: "space-between"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_n_tag, {
                      type: _ctx.orderStatuses[order.status.id],
                      class: "rounded-md"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Status: " + _toDisplayString(order.status.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["type"]),
                    _createVNode(_component_n_space, {
                      class: "leading-none",
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(order.customer) + " ", 1),
                        _createVNode(_component_n_icon, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_Person20Filled)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["theme-overrides", "onClick"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}