
import { computed, defineComponent, inject, PropType } from "vue";

import { NCard, NGrid, NGridItem, NIcon, NSpace, useThemeVars } from "naive-ui";

import { Lightbulb20Regular, LightbulbFilament20Regular } from "@vicons/fluent";

import { Asset, AssetData } from "@/models/asset.model";

import { useAssetsStore } from "@/stores/assets.store";

export default defineComponent({
  name: "SwitchActuatorComponent",

  props: {
    asset: {
      type: Object as PropType<Asset>,
      required: true,
    },

    data: {
      type: Object as PropType<AssetData[]>,
      required: true,
    },
  },

  components: {
    NCard,
    NGrid,
    NGridItem,
    NIcon,
    NSpace,

    Lightbulb20Regular,
    LightbulbFilament20Regular,
  },

  setup(props) {
    const themeVars = useThemeVars();
    const assetsStore = useAssetsStore();

    const fb = computed(() => props.data.find((_) => _.subtype == ""));

    function setCmdData(event: Event) {
      let switch_cmd: number;

      if (event.type == "click") switch_cmd = +!fb.value?.data.switch_cmd;
      else switch_cmd = +event;

      const payload = {
        asset_id: props.asset.asset_id,
        subtype: "cmd",
        data: {
          switch_cmd: switch_cmd,
        },
      };

      assetsStore.setAssetData(payload);
    }

    return {
      env: inject("env"),

      themeVars,
      fb,

      setCmdData,
    };
  },
});
