
import { computed, defineComponent, PropType } from "vue";

import { NCard, NIcon } from "naive-ui";

import { Flash20Regular } from "@vicons/fluent";

import { Asset, AssetData } from "@/models/asset.model";

export default defineComponent({
  name: "EnergyMeterComponent",

  props: {
    asset: {
      type: Object as PropType<Asset>,
      required: true,
    },

    data: {
      type: Object as PropType<AssetData[]>,
      required: true,
    },
  },

  components: {
    NCard,
    NIcon,

    Flash20Regular,
  },

  setup(props) {
    const fb = computed(() => props.data.find((_) => _.subtype == ""));

    return {
      fb,
    };
  },
});
