import ApiHelper from "./api.helper";

import axios, {
  AxiosResponse,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosError,
} from "axios";

import qs from "qs";

import { useApiStore } from "@/stores/api.store";
import { useDeviceStore } from "@/stores/device.store";

interface ApiRequestInterface {
  setAuthorizationHeader(): void;
  setInterceptorsRequest(): void;
  setInterceptorsResponse(): void;
}

type Headers = {
  Authorization?: string;
};

export default class ApiService implements ApiRequestInterface {
  private static _self: ApiService = new ApiService();

  private _axiosInstance: AxiosInstance;

  headers: Headers = {};

  constructor() {
    this._axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_CORE_URL,
    });

    this.setInterceptorsRequest();
    this.setInterceptorsResponse();
  }

  setAuthorizationHeader() {
    const core_api_token = localStorage.getItem("core_api_token");
    this.headers["Authorization"] = `Bearer ${core_api_token}`;
  }

  setInterceptorsRequest(): void {
    this._axiosInstance.interceptors.request.use(
      (config): AxiosRequestConfig => {
        const apiStore = useApiStore();
        apiStore.apiFetching = true;

        return config;
      },
      (error) => {
        const apiStore = useApiStore();
        apiStore.apiFetching = false;

        return error;
      }
    );
  }

  setInterceptorsResponse(): void {
    this._axiosInstance.interceptors.response.use(
      (response): Promise<AxiosResponse> => {
        const apiStore = useApiStore();
        apiStore.apiFetching = false;

        const deviceStore = useDeviceStore();
        deviceStore.updateDeviceInfo();

        return ApiHelper.checkResponse(response);
      },
      (error: AxiosError): Promise<AxiosResponse> => {
        const apiStore = useApiStore();
        apiStore.apiFetching = false;

        return ApiHelper.handleError(error);
      }
    );
  }

  static async get(uri: string, params?: object): Promise<AxiosResponse> {
    this._self.setAuthorizationHeader();
    return this._self._axiosInstance.get(uri, {
      headers: this._self.headers,
      params: params,
      paramsSerializer: (_) => qs.stringify(_),
    });
  }

  static async post(uri: string, data?: object): Promise<AxiosResponse> {
    this._self.setAuthorizationHeader();
    return this._self._axiosInstance.post(uri, data, {
      headers: this._self.headers,
    });
  }

  static patch(uri: string, data?: object): Promise<AxiosResponse> {
    this._self.setAuthorizationHeader();
    return this._self._axiosInstance.patch(uri, data, {
      headers: this._self.headers,
    });
  }

  static put(uri: string, data?: object): Promise<AxiosResponse> {
    this._self.setAuthorizationHeader();
    return this._self._axiosInstance.put(uri, data, {
      headers: this._self.headers,
    });
  }

  static delete(uri: string): Promise<AxiosResponse> {
    this._self.setAuthorizationHeader();
    return this._self._axiosInstance.delete(uri, {
      headers: this._self.headers,
    });
  }
}
