
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { NCard, NGrid, NGridItem, useThemeVars } from "naive-ui";

import { Asset } from "@/models/asset.model";
import { useAssetsStore } from "@/stores/assets.store";

export default defineComponent({
  name: "AssetTypeRoomsComponent",

  props: {
    asset_type: {
      type: String,
      required: true,
    },
  },

  components: {
    NCard,
    NGrid,
    NGridItem,
  },

  setup(props) {
    const router = useRouter();
    const assetsStore = useAssetsStore();

    const assets = ref<Asset[]>([]);

    onMounted(() => {
      assets.value = assetsStore.assets.filter((asset) => {
        return asset.asset_type == props.asset_type;
      });
    });

    function getAssetsByLocRef(asset_id: number) {
      router.push({ path: `/loc-ref/${asset_id}` });
    }

    return {
      assets,
      themeVars: useThemeVars(),
      getAssetsByLocRef,
    };
  },
});
