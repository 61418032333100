
import { computed, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";

import { Tile } from "@/models/tile.model";
import { useTilesStore } from "@/stores/tiles.store";

import category_ from "../components/Category.vue";
import service_ from "../components/Service.vue";

export default defineComponent({
  name: "TilePage",

  props: {
    id: {
      type: Number,
    },
  },

  components: {
    category_,
    service_,
  },

  setup(props) {
    const router = useRouter();
    const tilesStore = useTilesStore();

    const tile = computed(() => {
      let filteredTile: Tile | undefined;

      const recursiveFilter = (filter, tiles: Tile[]) => {
        return tiles.filter((tile: Tile) => {
          if (tile.id == props.id) filteredTile = tile;
          if (tile.children) recursiveFilter(filter, tile.children);
        });
      };

      recursiveFilter((tile: Tile) => tile.id == props.id, tilesStore.tiles);

      return filteredTile;
    });

    const component = computed(() => {
      return tile.value?.type.name.replace(/\s/g, "").toLowerCase() + "_";
    });

    onMounted(() => {
      if (tilesStore.tiles.length == 0) router.push({ name: "asset-types" });
    });

    return {
      component,
      tile,
    };
  },
});
