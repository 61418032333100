
import "vfonts/FiraSans.css";

import { computed, defineComponent } from "vue";

import {
  GlobalThemeOverrides,
  NConfigProvider,
  NDialogProvider,
  NEl,
  NMessageProvider,
  dateDeDE,
  deDE,
} from "naive-ui";

import { useThemeStore } from "@/stores/theme.store";

import InitLayout from "@/layouts/InitLayout.vue";

export default defineComponent({
  components: {
    InitLayout,
    NConfigProvider,
    NDialogProvider,
    NEl,
    NMessageProvider,
  },

  setup() {
    const themeStore = useThemeStore();

    const darkThemeOverrides: GlobalThemeOverrides = {
      common: {
        primaryColor: "#5f9e42",
        borderRadius: "8px",
      },

      Card: {
        // titleTextColor: "white",
        // textColor: "black",
      },
    };

    const lighThemeOverrides: GlobalThemeOverrides = {
      common: {
        primaryColor: "#5f9e42",
        borderRadius: "8px",
      },

      Card: {},
    };

    const themeOverrides = computed(() =>
      themeStore.theme.name == "dark" ? darkThemeOverrides : lighThemeOverrides
    );

    return {
      themeStore,
      themeOverrides,
      dateDeDE,
      deDE,
    };
  },
});
