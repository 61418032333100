
import { computed, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";

import {
  NCard,
  NGrid,
  NGridItem,
  NIcon,
  NSpace,
  NTag,
  NTime,
  useThemeVars,
} from "naive-ui";

import { Order } from "@/models/tile.model";
import { useTilesStore } from "@/stores/tiles.store";

import { orderStatuses } from "../constants/statuses";

import { Person20Filled } from "@vicons/fluent";

export default defineComponent({
  name: "OrdersPage",

  components: {
    NCard,
    NGrid,
    NGridItem,
    NIcon,
    NSpace,
    NTag,
    NTime,

    Person20Filled,
  },

  setup() {
    const router = useRouter();
    const tilesStore = useTilesStore();

    const orders = computed<Order[]>({
      get: () => tilesStore.orders,
      set: (value) => (tilesStore.orders = value),
    });

    onMounted(() => {
      tilesStore.fetchOrders().then((response) => {
        orders.value = response.data.data;
      });
    });

    function routerPush(id: number) {
      router.push({ name: "order", params: { id } });
    }

    return {
      themeVars: useThemeVars(),
      orderStatuses,
      orders,

      routerPush,
    };
  },
});
