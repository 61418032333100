import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "text-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_checkbox = _resolveComponent("n-checkbox")!
  const _component_n_space = _resolveComponent("n-space")!
  const _component_n_checkbox_group = _resolveComponent("n-checkbox-group")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createBlock(_component_n_form_item, {
    path: `${_ctx.attribute.id}`,
    "show-feedback": false,
    rule: {
      type: 'array',
      required: true,
      trigger: ['change'],
    }
  }, {
    label: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.attribute.label), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_n_checkbox_group, {
        value: _ctx.localValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
        name: "checkboxgroup"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_n_space, { vertical: "" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attribute.items, (item) => {
                return (_openBlock(), _createBlock(_component_n_checkbox, {
                  key: item.id,
                  value: item.name,
                  label: item.name,
                  size: "large"
                }, null, 8, ["value", "label"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"])
    ]),
    _: 1
  }, 8, ["path"]))
}