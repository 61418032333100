import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "h-full relative flex justify-center items-center p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LightbulbFilament20Regular = _resolveComponent("LightbulbFilament20Regular")!
  const _component_Lightbulb20Regular = _resolveComponent("Lightbulb20Regular")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_space = _resolveComponent("n-space")!
  const _component_n_grid_item = _resolveComponent("n-grid-item")!
  const _component_n_badge = _resolveComponent("n-badge")!
  const _component_BrightnessHigh20Filled = _resolveComponent("BrightnessHigh20Filled")!
  const _component_n_grid = _resolveComponent("n-grid")!
  const _component_DrawerComponent = _resolveComponent("DrawerComponent")!
  const _component_n_card = _resolveComponent("n-card")!

  return (_ctx.fb)
    ? (_openBlock(), _createBlock(_component_n_card, {
        key: 0,
        size: "small",
        id: 'asset-id-' + _ctx.asset.asset_id,
        class: "shadow-xl overflow-hidden"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_n_grid, {
            cols: "4",
            "item-responsive": "",
            responsive: "screen"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_n_grid_item, { span: "4" }, {
                default: _withCtx(() => [
                  (_ctx.env == 'development')
                    ? (_openBlock(), _createElementBlock("pre", _hoisted_1, _toDisplayString(_ctx.fb), 1))
                    : _createCommentVNode("", true),
                  _createVNode(_component_n_space, {
                    "item-style": "min-width: 0",
                    align: "center",
                    size: 16,
                    wrap: false
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_n_icon, {
                        class: "opacity-50",
                        size: 60
                      }, {
                        default: _withCtx(() => [
                          (_ctx.fb.data.switch_fb)
                            ? (_openBlock(), _createBlock(_component_LightbulbFilament20Regular, { key: 0 }))
                            : (_openBlock(), _createBlock(_component_Lightbulb20Regular, { key: 1 }))
                        ]),
                        _: 1
                      }),
                      _renderSlot(_ctx.$slots, "content")
                    ]),
                    _: 3
                  })
                ]),
                _: 3
              }),
              _createVNode(_component_n_grid_item, {
                span: "2",
                class: _normalizeClass(["cursor-pointer h-16 bg-[var(--code-color)] border-b-4 border-[var(--border-color)] rounded-md active:border-b-2", {
          '!bg-[var(--primary-color)]': _ctx.fb.data.switch_fb,
          '!border-b-lime-700': _ctx.fb.data.switch_fb,
        }]),
                onClick: _ctx.setCmdData
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    (_ctx.fb.data.switch_fb)
                      ? (_openBlock(), _createBlock(_component_n_icon, {
                          key: 0,
                          color: "#FFFFFF",
                          size: 30
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_LightbulbFilament20Regular)
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_n_icon, {
                          key: 1,
                          color: _ctx.themeVars.textColorBase,
                          class: "opacity-40",
                          size: 30
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Lightbulb20Regular)
                          ]),
                          _: 1
                        }, 8, ["color"]))
                  ])
                ]),
                _: 1
              }, 8, ["class", "onClick"]),
              _createVNode(_component_n_grid_item, {
                span: "2",
                class: "cursor-pointer h-16 bg-[var(--code-color)] border-b-4 border-[var(--border-color)] rounded-md active:border-b-2"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "h-full relative flex justify-center items-center p-4",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.attrDrawer = true))
                  }, [
                    _createVNode(_component_n_badge, {
                      class: "absolute top-2 right-2",
                      value: _ctx.sliderValue,
                      color: "gray"
                    }, null, 8, ["value"]),
                    _createVNode(_component_n_icon, {
                      color: _ctx.themeVars.textColorBase,
                      class: "opacity-40",
                      size: 30
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BrightnessHigh20Filled)
                      ]),
                      _: 1
                    }, 8, ["color"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 3
          }),
          _createVNode(_component_DrawerComponent, {
            show: _ctx.attrDrawer,
            "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.attrDrawer) = $event)),
            value: _ctx.sliderValue,
            "onUpdate:value": [
              _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sliderValue) = $event)),
              _ctx.debounceSetSpData
            ],
            marks: { 0: 'dunkel', 100: 'hell' },
            _targ: '#asset-id-' + _ctx.asset.asset_id
          }, null, 8, ["show", "value", "_targ", "onUpdate:value"])
        ]),
        _: 3
      }, 8, ["id"]))
    : _createCommentVNode("", true)
}