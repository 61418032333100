import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssetComponent = _resolveComponent("AssetComponent")!
  const _component_n_grid_item = _resolveComponent("n-grid-item")!
  const _component_n_grid = _resolveComponent("n-grid")!

  return (_openBlock(), _createBlock(_component_n_grid, {
    cols: "1 s:2 m:3",
    responsive: "screen",
    "item-responsive": ""
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assets, (asset) => {
        return (_openBlock(), _createBlock(_component_n_grid_item, {
          key: asset.asset_id
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AssetComponent, { asset: asset }, null, 8, ["asset"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}