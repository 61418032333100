
import { computed, defineComponent, PropType } from "vue";

import { NButton, NCard, NIcon } from "naive-ui";

import { CursorClick20Regular } from "@vicons/fluent";

import { Asset, AssetData } from "@/models/asset.model";

import { useAssetsStore } from "@/stores/assets.store";

export default defineComponent({
  name: "VirtualSceneComponent",

  props: {
    asset: {
      type: Object as PropType<Asset>,
      required: true,
    },

    data: {
      type: Object as PropType<AssetData[]>,
      required: true,
    },
  },

  components: {
    NButton,
    NCard,
    NIcon,

    CursorClick20Regular,
  },

  setup(props) {
    const assetsStore = useAssetsStore();

    const fb = computed(() => props.data.find((_) => _.subtype == ""));

    function setCmdData() {
      const payload = {
        asset_id: props.asset.asset_id,
        subtype: "cmd",
        data: {
          switch_cmd: 1,
        },
      };

      assetsStore.setAssetData(payload).then(() => {
        payload.data.switch_cmd = 0;
        assetsStore.setAssetData(payload);
      });
    }

    return {
      fb,

      setCmdData,
    };
  },
});
