import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "h-full text-2xl font-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Temperature20Regular = _resolveComponent("Temperature20Regular")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_space = _resolveComponent("n-space")!
  const _component_n_grid_item = _resolveComponent("n-grid-item")!
  const _component_n_badge = _resolveComponent("n-badge")!
  const _component_CaretDown20Filled = _resolveComponent("CaretDown20Filled")!
  const _component_CaretUp20Filled = _resolveComponent("CaretUp20Filled")!
  const _component_n_grid = _resolveComponent("n-grid")!
  const _component_DrawerComponent = _resolveComponent("DrawerComponent")!
  const _component_n_card = _resolveComponent("n-card")!

  return (_ctx.fb)
    ? (_openBlock(), _createBlock(_component_n_card, {
        key: 0,
        size: "small",
        id: 'asset-id-' + _ctx.asset.asset_id,
        class: "shadow-xl overflow-hidden"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_n_grid, {
            cols: "4",
            "item-responsive": "",
            responsive: "screen"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_n_grid_item, { span: "4" }, {
                default: _withCtx(() => [
                  (_ctx.env == 'development')
                    ? (_openBlock(), _createElementBlock("pre", _hoisted_1, _toDisplayString(_ctx.fb), 1))
                    : _createCommentVNode("", true),
                  _createVNode(_component_n_space, {
                    "item-style": "min-width: 0",
                    align: "center",
                    size: 16,
                    wrap: false
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_n_icon, {
                        class: "opacity-50",
                        size: 60
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Temperature20Regular)
                        ]),
                        _: 1
                      }),
                      _renderSlot(_ctx.$slots, "content")
                    ]),
                    _: 3
                  })
                ]),
                _: 3
              }),
              _createVNode(_component_n_grid_item, {
                span: "4",
                class: "cursor-pointer h-16 bg-[var(--code-color)] border-b-4 border-[var(--border-color)] rounded-md active:border-b-2"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "h-full relative flex justify-center items-center p-4",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.attrDrawer = true))
                  }, [
                    _createVNode(_component_n_badge, {
                      class: "absolute top-2 right-2",
                      value: _ctx.sliderValue + '°C',
                      color: "gray"
                    }, null, 8, ["value"]),
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_n_space, {
                        class: "h-full",
                        align: "end",
                        justify: "end",
                        size: 0
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_n_icon, null, {
                            default: _withCtx(() => [
                              (_ctx.fb.data.temperature > _ctx.sliderValue)
                                ? (_openBlock(), _createBlock(_component_CaretDown20Filled, { key: 0 }))
                                : _createCommentVNode("", true),
                              (_ctx.fb.data.temperature < _ctx.sliderValue)
                                ? (_openBlock(), _createBlock(_component_CaretUp20Filled, { key: 1 }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }),
                          _createTextVNode(" " + _toDisplayString(_ctx.fb.data.temperature) + "°C ", 1)
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 3
          }),
          _createVNode(_component_DrawerComponent, {
            show: _ctx.attrDrawer,
            "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.attrDrawer) = $event)),
            value: _ctx.sliderValue,
            "onUpdate:value": [
              _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sliderValue) = $event)),
              _ctx.debounceSetSpData
            ],
            min: 10,
            max: 35,
            marks: { 10: 'kälter', 35: 'wärmer' },
            _targ: '#asset-id-' + _ctx.asset.asset_id,
            sign: "°C"
          }, null, 8, ["show", "value", "_targ", "onUpdate:value"])
        ]),
        _: 3
      }, 8, ["id"]))
    : _createCommentVNode("", true)
}