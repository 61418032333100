import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_input_number = _resolveComponent("n-input-number")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createBlock(_component_n_form_item, {
    path: `${_ctx.attribute.id}`,
    "show-feedback": false,
    rule: {
      type: 'number',
      required: true,
      trigger: ['input', 'blur'],
    }
  }, {
    label: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.attribute.label), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_n_input_number, {
        value: _ctx.localValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
        min: 0,
        size: "large",
        class: "w-full"
      }, null, 8, ["value"])
    ]),
    _: 1
  }, 8, ["path"]))
}