
import { defineComponent, PropType } from "vue";

import { NFormItem, NRadio, NRadioGroup, NSpace } from "naive-ui";

import { useModelValue } from "@/composables/use-model-value";

import { Attribute } from "@/models/tile.model";

export default defineComponent({
  name: "RadioGroupElement",

  props: {
    modelValue: String,

    attribute: {
      type: Object as PropType<Attribute>,
      required: true,
    },
  },

  components: {
    NFormItem,
    NRadio,
    NRadioGroup,
    NSpace,
  },

  setup(props, context) {
    const localValue = useModelValue(props, context.emit);

    return {
      localValue,
    };
  },
});
