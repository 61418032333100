
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

import { NCard, NGrid, NGridItem, useThemeVars } from "naive-ui";

export default defineComponent({
  name: "AssetTypeDevicesComponent",

  components: {
    NCard,
    NGrid,
    NGridItem,
  },

  setup() {
    const router = useRouter();

    function getAssets(query: string[]) {
      router.push({ path: `/asset-types/${query}` });
    }

    return {
      themeVars: useThemeVars(),

      getAssets,
    };
  },
});
