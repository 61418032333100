import { defineStore } from "pinia";

import Api from "@/services/Api/api.service";

export const useApiStore = defineStore("apiStore", {
  state: () => {
    return {
      apiFetching: false,
    };
  },

  actions: {
    token() {
      return Api.get("/api/token");
    },
  },
});
