import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "truncate leading-6 text-xl font-bold" }
const _hoisted_2 = { class: "truncate opacity-60" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_space = _resolveComponent("n-space")!

  return (_ctx.asset && _ctx.assetData.length > 0)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.components[_ctx.asset.asset_type]), {
        key: 0,
        asset: _ctx.asset,
        data: _ctx.assetData
      }, {
        content: _withCtx(() => [
          _createVNode(_component_n_space, {
            size: 4,
            vertical: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.asset.description) + " " + _toDisplayString(_ctx.assetType ? _ctx.assetType.translation.de : ""), 1),
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.asset.name), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["asset", "data"]))
    : _createCommentVNode("", true)
}