
import { defineComponent } from "vue";

import {
  NButton,
  NDrawer,
  NDrawerContent,
  NIcon,
  NSlider,
  NSpace,
} from "naive-ui";

import { DismissCircle20Regular } from "@vicons/fluent";

import { useModelValue } from "@/composables/use-model-value";

export default defineComponent({
  name: "DrawerComponent",

  components: {
    NButton,
    NDrawer,
    NDrawerContent,
    NIcon,
    NSlider,
    NSpace,

    DismissCircle20Regular,
  },

  props: {
    show: Boolean,
    value: Number,
    _targ: String,

    min: {
      type: Number,
      default: 0,
    },

    max: {
      type: Number,
      default: 100,
    },

    marks: {
      type: Object,
      default: () => Object({ 0: "0%", 100: "100%" }),
    },

    sign: {
      type: String,
      default: "%",
    },
  },

  setup(props, context) {
    const localValue = useModelValue(props, context.emit, "show");
    const localSliderValue = useModelValue(props, context.emit, "value");

    return {
      localValue,
      localSliderValue,
    };
  },
});
