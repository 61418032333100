import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-lg font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DismissCircle20Regular = _resolveComponent("DismissCircle20Regular")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_space = _resolveComponent("n-space")!
  const _component_n_slider = _resolveComponent("n-slider")!
  const _component_n_drawer_content = _resolveComponent("n-drawer-content")!
  const _component_n_drawer = _resolveComponent("n-drawer")!

  return (_openBlock(), _createBlock(_component_n_drawer, {
    show: _ctx.localValue,
    "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localValue) = $event)),
    placement: "top",
    class: "rounded-md",
    "auto-focus": false,
    height: 130,
    to: _ctx._targ
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_drawer_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_n_space, { vertical: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_n_space, {
                align: "center",
                justify: "space-between"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.localSliderValue) + _toDisplayString(_ctx.sign), 1),
                  _createVNode(_component_n_button, {
                    text: "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.localValue = false))
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_n_icon, { size: 32 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_DismissCircle20Regular)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_n_slider, {
                value: _ctx.localSliderValue,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localSliderValue) = $event)),
                min: _ctx.min,
                max: _ctx.max,
                marks: _ctx.marks
              }, null, 8, ["value", "min", "max", "marks"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show", "to"]))
}