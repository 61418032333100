
import { defineComponent, onMounted } from "vue";
import { Device } from "@capacitor/device";

import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { SplashScreen } from "@capacitor/splash-screen";
import ApkUpdater from "cordova-plugin-apkupdater";

import { useMessage } from "naive-ui";

import { message, setMessage } from "@/composables/message.composable";
import { echo } from "@/plugins/pusher";

export default defineComponent({
  name: "InitLayout",

  provide: {
    env: process.env.NODE_ENV,
  },

  setup() {
    setMessage(useMessage);

    onMounted(async () => {
      const deviceId = await Device.getId();

      echo
        .channel(`smarthome-bonacasa.${deviceId.uuid}`)
        .listen(
          ".App\\Events\\Smarthome\\Device\\SmartHomeDeviceNewVersionEvent",
          function (data) {
            console.log(data);

            message.loading("Remote update requested");

            if (data.url) {
              updateApp(data.url);
            }
          }
        );
    });

    async function updateApp(url: string) {
      let version = { version: "" };

      version = await CapacitorUpdater.download({
        url: url,
      });

      if (version.version != "") {
        SplashScreen.show();

        try {
          await CapacitorUpdater.set(version);
          message.success(`App wurde erfolgreich aktualisiert`);
        } catch (error) {
          SplashScreen.hide();
        }
      }
    }

    async function updateApk(url: string) {
      await ApkUpdater.download(url, {
        onDownloadProgress: (progress) => {
          console.log(progress);
        },
      });

      // TODO: Remove all dists before install
      // await CapacitorUpdater.reset();

      await ApkUpdater.install()
        .then((value) => {
          alert(JSON.stringify(value));
        })
        .catch((reason) => {
          alert(JSON.stringify(reason));
        });
    }
  },
});
