
import { defineComponent, PropType } from "vue";
import { useRouter } from "vue-router";

import { NCard, NGrid, NGridItem, useThemeVars } from "naive-ui";

import { Tile } from "@/models/tile.model";

export default defineComponent({
  name: "CategoryComponent",

  props: {
    tile: {
      type: Object as PropType<Tile>,
      required: true,
    },
  },

  components: {
    NCard,
    NGrid,
    NGridItem,
  },

  setup() {
    const router = useRouter();

    function routerPush(id: number) {
      router.push({ name: "tile", params: { id } });
    }

    return {
      themeVars: useThemeVars(),

      routerPush,
    };
  },
});
