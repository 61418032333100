import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InitLayout = _resolveComponent("InitLayout")!
  const _component_n_el = _resolveComponent("n-el")!
  const _component_n_message_provider = _resolveComponent("n-message-provider")!
  const _component_n_dialog_provider = _resolveComponent("n-dialog-provider")!
  const _component_n_config_provider = _resolveComponent("n-config-provider")!

  return (_openBlock(), _createBlock(_component_n_config_provider, {
    theme: _ctx.themeStore.theme,
    "theme-overrides": _ctx.themeOverrides,
    "date-locale": _ctx.dateDeDE,
    locale: _ctx.deDE
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_dialog_provider, null, {
        default: _withCtx(() => [
          _createVNode(_component_n_message_provider, null, {
            default: _withCtx(() => [
              _createVNode(_component_n_el, null, {
                default: _withCtx(() => [
                  _createVNode(_component_InitLayout)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["theme", "theme-overrides", "date-locale", "locale"]))
}