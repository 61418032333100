import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssetType_Rooms = _resolveComponent("AssetType_Rooms")!
  const _component_n_tab_pane = _resolveComponent("n-tab-pane")!
  const _component_AssetType_Devices = _resolveComponent("AssetType_Devices")!
  const _component_AssetType_Scenes = _resolveComponent("AssetType_Scenes")!
  const _component_Flash20Regular = _resolveComponent("Flash20Regular")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_AssetType_Power = _resolveComponent("AssetType_Power")!
  const _component_Tiles = _resolveComponent("Tiles")!
  const _component_n_tabs = _resolveComponent("n-tabs")!

  return (_openBlock(), _createBlock(_component_n_tabs, {
    value: _ctx.tabsValue,
    "onUpdate:value": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tabsValue) = $event)),
      _ctx.setCurrentTab
    ],
    size: "large"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_tab_pane, {
        name: "rooms",
        tab: "Räume"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AssetType_Rooms, { asset_type: "Space" })
        ]),
        _: 1
      }),
      _createVNode(_component_n_tab_pane, {
        name: "devices",
        tab: "Geräte"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AssetType_Devices)
        ]),
        _: 1
      }),
      _createVNode(_component_n_tab_pane, {
        name: "scenes",
        tab: "Szenen"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AssetType_Scenes, { asset_type: "F@H - Virtual Scene" })
        ]),
        _: 1
      }),
      _createVNode(_component_n_tab_pane, { name: "power" }, {
        tab: _withCtx(() => [
          _createVNode(_component_n_icon, { size: 20 }, {
            default: _withCtx(() => [
              _createVNode(_component_Flash20Regular)
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_AssetType_Power, { asset_type: "Niagara" })
        ]),
        _: 1
      }),
      _createVNode(_component_n_tab_pane, {
        name: "services",
        tab: "bonaServices"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Tiles)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "onUpdate:value"]))
}