
import { computed, defineComponent } from "vue";

import { NFormItem, NSelect } from "naive-ui";

import { useModelValue } from "@/composables/use-model-value";

import { Inhabitant } from "@/models/tile.model";
import { useTilesStore } from "@/stores/tiles.store";

export default defineComponent({
  name: "RecipientElement",

  props: {
    modelValue: Number,
  },

  components: {
    NFormItem,
    NSelect,
  },

  setup(props, context) {
    const localValue = useModelValue(props, context.emit);
    const tilesStore = useTilesStore();

    const inhabitantsOptions = computed(() => {
      return tilesStore.inhabitants.map((inhabitant: Inhabitant) => {
        return {
          label: inhabitant.full_name,
          value: inhabitant.id,
        };
      });
    });

    return {
      localValue,
      inhabitantsOptions,
    };
  },
});
