import {
  createRouter,
  createWebHashHistory,
  RouteLocation,
  RouteRecordRaw,
} from "vue-router";

import { EventSourcePolyfill } from "event-source-polyfill";

import AuthLayout from "@/layouts/AuthLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";

import Assets from "@/modules/Assets/pages/Assets.vue";
import AssetTypes from "@/modules/Assets/pages/AssetTypes.vue";
import Tile from "@/modules/Tiles/pages/Tile.vue";
import Order from "@/modules/Orders/pages/Order.vue";
import Orders from "@/modules/Orders/pages/Orders.vue";

import { message } from "@/composables/message.composable";

import { Asset } from "@/models/asset.model";
import { useApiStore } from "@/stores/api.store";
import { useAssetsStore } from "@/stores/assets.store";

const refreshTokenIntervalMinutes = 60;
let refreshTokenIntervalID: number;

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/asset-types",
    component: MainLayout,

    beforeEnter: (_, __, next) => {
      const core_api_token = localStorage.getItem("core_api_token");

      if (!core_api_token) next("/auth");
      else _initAssetsStore().then(next);
    },

    children: [
      {
        path: "/asset-types",
        name: "asset-types",
        component: AssetTypes,
      },

      {
        path: "/asset-types/:type",
        name: "asset-type",
        component: Assets,

        props: (route: RouteLocation) => ({
          field: "asset_type",
          query: String(`${route.params.type}`),
        }),
      },

      {
        path: "/loc-ref/:id",
        component: Assets,

        props: (route: RouteLocation) => ({
          field: "loc_ref",
          query: String(route.params.id),
        }),
      },

      {
        path: "/tile/:id([0-9]*)",
        name: "tile",
        component: Tile,

        props: (route: RouteLocation) => ({
          id: Number(route.params.id),
        }),
      },

      {
        path: "/orders",
        name: "orders",
        component: Orders,
      },

      {
        path: "/orders/:id([0-9]*)",
        name: "order",
        component: Order,

        props: (route: RouteLocation) => ({
          id: Number(route.params.id),
        }),
      },
    ],
  },
  {
    path: "/auth",
    component: AuthLayout,

    beforeEnter: (_, __, next) => {
      clearInterval(refreshTokenIntervalID);

      const core_api_token = localStorage.getItem("core_api_token");

      if (core_api_token) next("/asset-types");
      else next();
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

async function _initAssetsStore() {
  const assetsStore = useAssetsStore();

  const assetsParams = {
    order: "description",
  };

  const assetsResponse = await assetsStore.fetchAssets(assetsParams);
  const assetAttributesResponse = await assetsStore.fetchAssetAttributes();
  const assetDataResponse = await assetsStore.fetchAssetData();
  const assetTypesResponse = await assetsStore.fetchAssetTypes();

  assetsStore.$patch((store) => {
    store.assets = assetsResponse.data;
    store.assetAttributes = assetAttributesResponse.data;
    store.assetData = assetDataResponse.data;
    store.assetTypes = assetTypesResponse.data;
  });

  document.getElementById("pure-preloader")?.remove();

  /// ↓ SSE EVENTS

  _initEventSource(assetsResponse.data);

  refreshTokenIntervalID = setInterval(
    () => _initEventSource(assetsResponse.data),
    60000 * refreshTokenIntervalMinutes
  );
}

async function _initEventSource(assets: Asset[]) {
  const apiStore = useApiStore();
  const assetsStore = useAssetsStore();

  message.loading("Aktualisieren des API-Token");
  const { data } = await apiStore.token();

  const assets_ids = assets.map((asset: Asset) => asset.asset_id);
  const eliona_url = process.env.VUE_APP_ELIONA_URL;

  const eventSource = new EventSourcePolyfill(
    `${eliona_url}/api/v1/sse/events?channels=heap&asset_id=${assets_ids.join()}`,
    { headers: { Authorization: `Bearer ${data.jwt}` } }
  );

  eventSource.addEventListener("heap", (event) => {
    const messageEvent = event as MessageEvent;
    const parsedData = JSON.parse(messageEvent.data);

    assetsStore.assetData.map((assetData) => {
      if (assetData.asset_id == parsedData.asset_id) {
        if (assetData.subtype == parsedData.subtype) {
          assetData.data = parsedData.data;
        }
      }
    });
  });
}

export default router;
