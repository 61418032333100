import Echo from "laravel-echo";
import Pusher from "pusher-js";

const options = {
  broadcaster: "pusher",
  enabledTransports: ["ws"],
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  httpHost: process.env.VUE_APP_PUSHER_HOST,
  httpPort: process.env.VUE_APP_PUSHER_PORT,
  wsHost: process.env.VUE_APP_PUSHER_WS_HOST,
  wsPort: process.env.VUE_APP_PUSHER_WS_PORT,
  wssPort: process.env.VUE_APP_PUSHER_WS_PORT,
  forceTLS: process.env.VUE_APP_PUSHER_FORCE_TLS == "true",
  disableStats: process.env.VUE_APP_PUSHER_DISABLE_STATS == "true",
  authEndpoint: process.env.VUE_APP_CORE_URL + "/api/broadcasting/auth",
  auth: {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("core_api_token"),
    },
  },
};

const pusher = new Pusher(options.key, options);

export const echo = new Echo({
  ...options,
  client: pusher,
});
