import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_card = _resolveComponent("n-card")!
  const _component_n_grid_item = _resolveComponent("n-grid-item")!
  const _component_n_grid = _resolveComponent("n-grid")!

  return (_openBlock(), _createBlock(_component_n_grid, {
    cols: "1 s:2 m:3",
    responsive: "screen",
    "item-responsive": ""
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tiles, (tile) => {
        return (_openBlock(), _createBlock(_component_n_grid_item, {
          key: tile.id
        }, {
          default: _withCtx(() => [
            _createVNode(_component_n_card, {
              class: "card-header-truncate",
              "theme-overrides": {
            color: _ctx.themeVars.primaryColor,
            titleTextColor: 'white',
          },
              onClick: ($event: any) => (_ctx.routerPush(tile.id))
            }, {
              header: _withCtx(() => [
                _createTextVNode(_toDisplayString(tile.name), 1)
              ]),
              _: 2
            }, 1032, ["theme-overrides", "onClick"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}