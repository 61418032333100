
import { defineComponent, onMounted, ref } from "vue";

import { NGrid, NGridItem } from "naive-ui";

import { Asset } from "@/models/asset.model";
import { useAssetsStore } from "@/stores/assets.store";

import AssetComponent from "./AssetComponent.vue";

export default defineComponent({
  name: "AssetTypeScenesComponent",

  props: {
    asset_type: {
      type: String,
      required: true,
    },
  },

  components: {
    NGrid,
    NGridItem,

    AssetComponent,
  },

  setup(props) {
    const assetsStore = useAssetsStore();

    const assets = ref<Asset[]>([]);

    onMounted(() => {
      assets.value = assetsStore.assets.filter((asset) => {
        return asset.asset_type.includes(props.asset_type);
      });
    });

    return { assets };
  },
});
