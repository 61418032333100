import { defineStore } from "pinia";
import { darkTheme, lightTheme, deDE } from "naive-ui";

export const useThemeStore = defineStore("themeStore", {
  state: () => {
    const theme = localStorage.getItem("current_theme");

    return {
      locale: deDE,
      theme: theme == "light" ? lightTheme : darkTheme,
    };
  },
});
