
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

import {
  FormInst,
  NButton,
  NCard,
  NForm,
  NFormItem,
  NGrid,
  NSpace,
  useThemeVars,
} from "naive-ui";

import { message } from "@/composables/message.composable";
import { useTilesStore } from "@/stores/tiles.store";

import Recipient from "./elements/Recipient.vue";

import checkboxgroup_ from "./elements/CheckboxGroup.vue";
import datepicker_ from "./elements/DatePicker.vue";
import input_ from "./elements/Input.vue";
import quantity_ from "./elements/InputNumber.vue";
import radiogroup_ from "./elements/RadioGroup.vue";
import textcontent_ from "./elements/TextContent.vue";
import timepicker_ from "./elements/TimePicker.vue";

export default defineComponent({
  name: "FormBuilder",

  props: {
    service: {
      type: Object,
      required: true,
    },
  },

  components: {
    NButton,
    NCard,
    NForm,
    NFormItem,
    NGrid,
    NSpace,

    Recipient,

    checkboxgroup_,
    datepicker_,
    input_,
    quantity_,
    radiogroup_,
    textcontent_,
    timepicker_,
  },

  setup(props) {
    const router = useRouter();

    const formRef = ref<FormInst>();
    const formData = ref({});

    function formValidate(event: MouseEvent) {
      event.preventDefault();

      formRef.value?.validate((errors) => {
        if (!errors) {
          const tilesStore = useTilesStore();

          const body = {
            formData: formData.value,
            service_id: props.service.id,
            person_id: formData.value["person_id"],
          };

          tilesStore.orderService(body).then((response) => {
            console.log(response);
            message.success("Bestellung wird bearbeitet");
            router.push({ name: "order", params: { id: response.data.id } });
          });
        } else {
          console.log(errors);
          message.error("Füllen Sie die erforderlichen Felder aus");
        }
      });
    }

    return {
      themeVars: useThemeVars(),
      formRef,
      formData,

      formValidate,
    };
  },
});
