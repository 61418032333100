
import { defineComponent, PropType } from "vue";

import { Attribute } from "@/models/tile.model";

export default defineComponent({
  name: "TextContentElement",

  props: {
    attribute: {
      type: Object as PropType<Attribute>,
      required: true,
    },
  },
});
