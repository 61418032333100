import { defineStore } from "pinia";

import { Device } from "@capacitor/device";

import Api from "@/services/Api/api.service";

import { APP_VERSION } from "@/app-version";

export const useDeviceStore = defineStore("deviceStore", {
  state: () => {
    return {};
  },

  actions: {
    async updateDeviceInfo() {
      const UPDATED_AT = localStorage.getItem("device_info_updated_at");

      if (UPDATED_AT != null) {
        const NOW = Date.now();
        const ONE_HOUR = 60 * 60 * 1000;
        const ONE_HOUR_AGO = NOW - ONE_HOUR;

        if (+UPDATED_AT > ONE_HOUR_AGO) return;
      }

      const deviceId = await Device.getId();
      const deviceInfo = await Device.getInfo();

      const formData = {
        uuid: deviceId.uuid,
        model: deviceInfo.model,
        platform: deviceInfo.platform,
        os: deviceInfo.operatingSystem,
        os_version: deviceInfo.osVersion,
        app_version: APP_VERSION,
      };

      localStorage.setItem("device_info_updated_at", `${Date.now()}`);

      return Api.post("/api/device-info", formData);
    },
  },
});
