
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

import { Device } from "@capacitor/device";

import {
  FormInst,
  FormRules,
  NButton,
  NForm,
  NFormItem,
  NInput,
} from "naive-ui";

import { APP_VERSION } from "@/app-version";

import { message } from "@/composables/message.composable";

import { useApiStore } from "@/stores/api.store";

import Api from "@/services/Api/api.service";

export default defineComponent({
  name: "LoginComponent",

  components: {
    NButton,
    NForm,
    NFormItem,
    NInput,
  },

  setup() {
    const formRef = ref<FormInst>();
    const apiStore = useApiStore();
    const router = useRouter();

    const formValue = ref({
      access_key: "",
    });

    const formRules: FormRules = {
      access_key: {
        required: true,
        message: "Erforderliches Feld",
        trigger: "input",
      },
    };

    async function signIn(event: MouseEvent) {
      event.preventDefault();

      const deviceId = await Device.getId();
      const deviceInfo = await Device.getInfo();

      formRef.value?.validate((errors) => {
        const formData = {
          access_key: formValue.value.access_key,
          uuid: deviceId.uuid,
          model: deviceInfo.model,
          platform: deviceInfo.platform,
          os: deviceInfo.operatingSystem,
          os_version: deviceInfo.osVersion,
          app_version: APP_VERSION,
        };

        if (!errors) {
          Api.post(`/api/auth/smarthome-devices/register`, formData)
            .then((response) => {
              const { data } = response.data;

              const aptData = {
                name: data.apartment.name,
                building: {
                  street: data.apartment.building.street,
                },
                building_complex: {
                  name: data.apartment.building.building_complex.name,
                },
              };

              localStorage.setItem("core_api_token", data.api_token);
              localStorage.setItem("apartment_data", JSON.stringify(aptData));

              router.replace("/asset-types");
            })
            .catch((error) => {
              for (const e in error.response?.data.errors) {
                message.error(error.response.data.errors[e][0]);
              }
            });
        } else {
          message.error("Validierungsfehler");
        }
      });
    }

    return {
      apiStore,
      formRef,
      formValue,
      formRules,

      signIn,
    };
  },
});
