
import { Component, defineComponent, onMounted, PropType, ref } from "vue";

import { NCard, NSpace } from "naive-ui";

import { Asset, AssetData, AssetType } from "@/models/asset.model";

import { useAssetsStore } from "@/stores/assets.store";

import AwningActuator from "../types/AwningActuator.vue";
import DimmActuator from "../types/DimmActuator.vue";
import EnergyMeter from "../types/EnergyMeter.vue";
import PowerMeter from "../types/PowerMeter.vue";
import RoomController from "../types/RoomController.vue";
import ShutterActuator from "../types/ShutterActuator.vue";
import SwitchActuator from "../types/SwitchActuator.vue";
import VirtualScene from "../types/VirtualScene.vue";

const components: Record<string, Component> = {
  "F@H - Awning Actuator": AwningActuator,
  "F@H - Dimm Actuator": DimmActuator,
  "F@H - Room Controller": RoomController,
  "F@H - Shutter Actuator": ShutterActuator,
  "F@H - Blind Actuator": ShutterActuator,
  "F@H - Switch Actuator": SwitchActuator,
  "F@H - Virtual Scene": VirtualScene,
  "Niagara Energy Meter": EnergyMeter,
  "Niagara Power Meter": PowerMeter,
};

export default defineComponent({
  name: "AssetComponent",

  props: {
    asset: {
      type: Object as PropType<Asset>,
      required: true,
    },
  },

  components: {
    NCard,
    NSpace,
  },

  setup(props) {
    const assetsStore = useAssetsStore();

    const assetData = ref<AssetData[]>([]);
    const assetType = ref<AssetType>();

    onMounted(() => {
      assetData.value = assetsStore.assetData.filter((asset) => {
        return asset.asset_id == props.asset.asset_id;
      });

      assetType.value = assetsStore.assetTypes.find((asset) => {
        return asset.asset_type == props.asset.asset_type;
      });
    });

    return { components, assetData, assetType };
  },
});
