import { defineStore } from "pinia";

import { Order, Tile } from "@/models/tile.model";

import Api from "@/services/Api/api.service";

export const useTilesStore = defineStore("tilesStore", {
  state: () => {
    return {
      tiles: [] as Tile[],
      orders: [] as Order[],
      inhabitants: [],
    };
  },

  actions: {
    fetchServices() {
      return Api.get("/api/smarthome/services");
    },

    fetchOrders() {
      return Api.get("/api/smarthome/orders");
    },

    orderService(payload: object) {
      return Api.post("/api/smarthome/order", payload);
    },

    cancelOrder(id: number) {
      return Api.patch(`/api/smarthome/order/${id}`);
    },
  },
});
