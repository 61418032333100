
import { defineComponent } from "vue";

import {
  NCard,
  NLayout,
  NLayoutFooter,
  NLayoutContent,
  NSpace,
  NTabs,
  NTabPane,
  NText,
} from "naive-ui";

import LoginComponent from "@/modules/Auth/components/LoginComponent.vue";

export default defineComponent({
  name: "AuthLayout",

  components: {
    LoginComponent,
    NCard,
    NLayout,
    NLayoutFooter,
    NLayoutContent,
    NSpace,
    NTabs,
    NTabPane,
    NText,
  },
});
