
import { Component, computed, defineComponent, h, ref } from "vue";
import { RouterLink, useRouter } from "vue-router";

import {
  NButton,
  NCard,
  NDrawer,
  NDrawerContent,
  NIcon,
  NLayout,
  NLayoutContent,
  NMenu,
  NSpace,
  NSpin,
  darkTheme,
  lightTheme,
  MenuOption,
} from "naive-ui";

import { APP_VERSION } from "@/app-version";

import { useApiStore } from "@/stores/api.store";
import { useThemeStore } from "@/stores/theme.store";

import {
  ArrowCircleLeft20Regular,
  HomePerson20Regular,
  List20Regular,
  Settings20Regular,
  Receipt20Regular,
  WeatherSunny20Regular,
} from "@vicons/fluent";

export default defineComponent({
  name: "MainLayout",

  components: {
    NButton,
    NCard,
    NDrawer,
    NDrawerContent,
    NIcon,
    NLayout,
    NLayoutContent,
    NMenu,
    NSpace,
    NSpin,

    ArrowCircleLeft20Regular,
    HomePerson20Regular,
    List20Regular,
    Settings20Regular,
    Receipt20Regular,
    WeatherSunny20Regular,
  },

  setup() {
    const router = useRouter();

    const apiStore = useApiStore();
    const themeStore = useThemeStore();

    const drawer = ref(false);
    const apartmentData = ref(
      JSON.parse(localStorage.getItem("apartment_data") as string)
    );

    const menuOptions: MenuOption[] = [
      {
        type: "group",
        label: "Menü",
        key: "menu",
        children: [
          {
            label: () =>
              h(
                RouterLink,
                {
                  to: {
                    name: "asset-types",
                  },
                },
                { default: () => "Home" }
              ),
            icon: renderIcon(HomePerson20Regular),
            key: "/asset-types",
          },
          {
            label: () =>
              h(
                RouterLink,
                {
                  to: {
                    name: "orders",
                  },
                },
                { default: () => "Bestellungen" }
              ),
            icon: renderIcon(Receipt20Regular),
            key: "/orders",
          },
          {
            label: "Einstellungen",
            icon: renderIcon(Settings20Regular),
            key: "/settings",
            children: [
              {
                type: "group",
                label: "Thema",
                key: "theme",
                children: [
                  {
                    label: "Thema ändern",
                    icon: renderIcon(WeatherSunny20Regular),
                    key: "toggle-theme",
                  },
                ],
              },
            ],
          },
        ],
      },
    ];

    function renderIcon(icon: Component) {
      return () => h(NIcon, null, { default: () => h(icon) });
    }

    function toggleTheme() {
      const { name } = themeStore.theme;

      themeStore.theme = name == "dark" ? lightTheme : darkTheme;
      localStorage.setItem("current_theme", themeStore.theme.name);
    }

    return {
      APP_VERSION,

      router,
      apiStore,
      drawer,
      menuOptions,
      apartmentData,
      nodeEnv: process.env.NODE_ENV,
      theme: computed(() => themeStore.theme.name),

      toggleTheme,

      menuUpdateValue(key: string, item: MenuOption) {
        drawer.value = false;

        switch (key) {
          case "toggle-theme":
            toggleTheme();
            break;
        }
      },
    };
  },
});
