
import { computed, defineComponent, inject, PropType, ref, watch } from "vue";

import {
  NBadge,
  NCard,
  NGrid,
  NGridItem,
  NIcon,
  NSpace,
  useThemeVars,
} from "naive-ui";

import { useDebounceFn } from "@vueuse/core";

import {
  CalendarArrowDown20Regular,
  DeviceMeetingRoomRemote20Regular,
} from "@vicons/fluent";

import { Asset, AssetData } from "@/models/asset.model";

import { useAssetsStore } from "@/stores/assets.store";

import DrawerComponent from "./components/DrawerComponent.vue";

export default defineComponent({
  name: "AwningActuatorComponent",

  props: {
    asset: {
      type: Object as PropType<Asset>,
      required: true,
    },

    data: {
      type: Object as PropType<AssetData[]>,
      required: true,
    },
  },

  components: {
    NBadge,
    NCard,
    NGrid,
    NGridItem,
    NIcon,
    NSpace,

    CalendarArrowDown20Regular,
    DeviceMeetingRoomRemote20Regular,
    DrawerComponent,
  },

  setup(props) {
    const themeVars = useThemeVars();
    const assetsStore = useAssetsStore();

    const fb = computed(() => props.data.find((_) => _.subtype == ""));

    const attrDrawer = ref(false);
    const sliderValue = ref(fb.value?.data.position_fb ?? 0);

    watch(
      () => fb.value?.data,
      (value, oldValue) => {
        if (value?.position_fb != oldValue?.position_fb) {
          sliderValue.value = value?.position_fb ?? 0;
        }
      }
    );

    const debounceSetSpData = useDebounceFn(setSpData, 1000);

    function setSpData(event: number) {
      attrDrawer.value = false;

      const payload = {
        asset_id: props.asset.asset_id,
        subtype: "sp",
        data: {
          position_sp: event,
        },
      };

      assetsStore.setAssetData(payload);
    }

    return {
      env: inject("env"),

      themeVars,
      attrDrawer,
      sliderValue,
      fb,

      debounceSetSpData,
    };
  },
});
