import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-xl font-bold" }
const _hoisted_2 = { class: "truncate text-xl font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Flash20Regular = _resolveComponent("Flash20Regular")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_card = _resolveComponent("n-card")!

  return (_ctx.fb)
    ? (_openBlock(), _createBlock(_component_n_card, {
        key: 0,
        "header-style": "align-items: start;"
      }, {
        header: _withCtx(() => [
          _createVNode(_component_n_icon, { size: 60 }, {
            default: _withCtx(() => [
              _createVNode(_component_Flash20Regular)
            ]),
            _: 1
          })
        ]),
        "header-extra": _withCtx(() => [
          _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.fb.data.value.toFixed()) + " W ", 1)
        ]),
        footer: _withCtx(() => [
          _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.asset.description), 1)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}