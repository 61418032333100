
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import {
  NButton,
  NCard,
  NIcon,
  NList,
  NListItem,
  NSpace,
  NTag,
  NTime,
  useDialog,
  useThemeVars,
} from "naive-ui";

import { Order } from "@/models/tile.model";
import { useTilesStore } from "@/stores/tiles.store";

import { orderStatuses } from "../constants/statuses";

import { ArrowStepBack20Filled, DismissCircle20Regular } from "@vicons/fluent";

export default defineComponent({
  name: "OrderPage",

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  components: {
    NButton,
    NCard,
    NIcon,
    NList,
    NListItem,
    NSpace,
    NTag,
    NTime,

    ArrowStepBack20Filled,
    DismissCircle20Regular,
  },

  setup(props) {
    const dialog = useDialog();
    const router = useRouter();
    const tilesStore = useTilesStore();

    const orders = computed<Order[]>({
      get: () => tilesStore.orders,
      set: (value) => (tilesStore.orders = value),
    });

    const order = ref<Order>();

    onMounted(() => {
      tilesStore.fetchOrders().then((response) => {
        orders.value = response.data.data;
        order.value = orders.value.find((order) => order.id == props.id);
      });
    });

    function cancelOrder() {
      dialog.error({
        title: "Sicher?",
        content: "Wollen Sie die Bestellung wirklich stornieren?",
        positiveText: "Ja, diese Bestellung stornieren",
        negativeText: "Nein",
        bordered: true,
        closable: false,
        iconPlacement: "top",
        positiveButtonProps: {
          size: "large",
        },
        negativeButtonProps: {
          size: "large",
        },
        onPositiveClick: async () => {
          await tilesStore.cancelOrder(props.id);

          tilesStore.fetchOrders().then((response) => {
            orders.value = response.data.data;
            order.value = orders.value.find((order) => order.id == props.id);

            // message.success("Success");
          });
        },
      });
    }

    function routerPush(options: object) {
      router.push(options);
    }

    return {
      themeVars: useThemeVars(),
      orderStatuses,
      orders,
      order,

      cancelOrder,
      routerPush,
    };
  },
});
