import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full h-300px bg-white text-center" }
const _hoisted_2 = { class: "font-black" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_image = _resolveComponent("n-image")!
  const _component_n_card = _resolveComponent("n-card")!
  const _component_FormBuilder = _resolveComponent("FormBuilder")!
  const _component_n_space = _resolveComponent("n-space")!

  return (_openBlock(), _createBlock(_component_n_card, null, {
    cover: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_n_image, {
          src: _ctx.tile.contentable.image?.url
        }, null, 8, ["src"])
      ])
    ]),
    header: _withCtx(() => [
      _createElementVNode("span", _hoisted_2, " Bestellung " + _toDisplayString(_ctx.tile.contentable.translation.name), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_n_space, {
        size: 16,
        vertical: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            innerHTML: _ctx.tile.contentable.translation.description
          }, null, 8, _hoisted_3),
          _createVNode(_component_n_card, {
            size: "small",
            embedded: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.tile.contentable.price) + " CHF", 1),
              _createTextVNode(" " + _toDisplayString(_ctx.tile.contentable.translation.price_description), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_FormBuilder, {
            service: _ctx.tile.contentable
          }, null, 8, ["service"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}