
import { defineComponent, PropType } from "vue";

import { NCard, NImage, NSpace } from "naive-ui";

import { Tile } from "@/models/tile.model";

import FormBuilder from "./builders/FormBuilder.vue";

export default defineComponent({
  name: "ServiceComponent",

  components: {
    NCard,
    NImage,
    NSpace,

    FormBuilder,
  },

  props: {
    tile: {
      type: Object as PropType<Tile>,
      required: true,
    },
  },
});
